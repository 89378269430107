'use client'

import { FunctionComponent, useContext, useEffect, useRef } from 'react'
import { gtmPush, sha256 } from '../../utils/gtmHelpers'
import { ContrastContext } from '../../context/ContrastContext'
import { GTMPageEvent } from '../../../domain/gtm'
import { UserContext } from '../../context/UserContext'
import { getCookie } from 'cookies-next'
import dayjs from 'dayjs'

type GtmProps = {
  type: GTMPageEvent['page_type']
  clientStatut?: string
  user?: {
    email?: string | null
    firstname?: string | null
    lastname?: string | null
  }
  address?: {
    city?: string | null
    country?: {
      code?: string | null
    }
    postcode?: string | null
    telephone?: string | null
  }
}
const GtmPushPageView: FunctionComponent<GtmProps> = (props) => {
  const { type, user: propUser, address: propAddress } = props
  const { contrast } = useContext(ContrastContext)
  const { user, hydrated } = useContext(UserContext)
  const userToken = getCookie('userToken')
  const triggeredRef = useRef(false)

  useEffect(() => {
    if (!hydrated || triggeredRef.current) {
      return
    }
    triggeredRef.current = true
    ;(async function () {
      let event: GTMPageEvent = {
        event: 'page_view',
        page_type: type,
        accessibility: contrast ? 'accessible' : 'standard',
        user_id: '',
        user_status: 'logged_out',
        client_status: '',

        cid_hash: '',
        cfn_hash: '',
        cln_hash: '',
        cp_hash: '',
        cci: '',
        cco: '',
        cpc: '',
        ca: '',
      }
      if (propUser) {
        event = {
          ...event,
          cid_hash: await sha256(propUser?.email),
          cfn_hash: await sha256(propUser?.firstname),
          cln_hash: await sha256(propUser?.lastname),
        }
        if (propAddress) {
          event.cci = propAddress.city ?? ''
          event.cco = propAddress.country?.code ?? ''
          event.cpc = propAddress.postcode ?? ''
          event.cp_hash = await sha256(propAddress.telephone)
        }
      } else if (userToken) {
        if (user || propUser) {
          event = {
            ...event,
            user_id: await sha256(user?.customer_id),
            user_status: 'logged_in',
            client_status: user?.has_orders ? 'ancien client' : 'nouveau client',

            cid_hash: await sha256(user?.email),
            cfn_hash: await sha256(user?.firstname),
            cln_hash: await sha256(user?.lastname),
          }
          if (user?.date_of_birth) {
            const dob = dayjs(user.date_of_birth)
            const age = dayjs().diff(dob, 'year')
            if (Number.isInteger(age) && age > 0 && age < 150) {
              event.ca = age
            }
          }

          let defaultAddress = user?.addresses?.find((a) => a.default_shipping)
          if (!defaultAddress) {
            defaultAddress = user?.addresses?.find((a) => a.default_billing)
          }
          if (defaultAddress) {
            event.cci = defaultAddress.city
            event.cco = defaultAddress.country.code
            event.cpc = defaultAddress.postcode
            if (defaultAddress.telephone && defaultAddress.telephone.length > 0) {
              event.cp_hash = await sha256(defaultAddress.telephone)
            }
          }
        }
      }
      gtmPush(event)
    })()
  }, [contrast, user, propUser, hydrated])

  return null
}

export default GtmPushPageView
