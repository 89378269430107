'use client'

import { useContext, useEffect, useRef } from 'react'
import { ModalContext, ModalContextType } from '../../context/ModalContext'
import { getCookie, setCookie } from 'cookies-next'
import ModalGeoip from '../Modal/ModalGeoip'
import useCurrentLocale from '../../../core/hook/useCurrentLocale'

type geoipResult = {
  country_code?: string
  country_name?: string
}

// const testIps = {
//   fr: "37.26.183.143",
//   es: "88.26.241.248",
//   de: "85.214.132.117",
//   it: "80.207.161.250",
//   us: "8.8.8.8",
//   uk: "102.178.34.47",
//   ch: "102.129.143.1",
//   tr: "101.44.220.1",
//   ci: "102.130.224.1",
//   jp: "1.0.16.1",
//   ru: "100.43.72.1",
//   cn: "1.0.1.1",
//   kr: "1.11.0.1",
//   ae: "102.177.124.1",
//   sa: "101.44.112.1",
// };

const storesByCountryCode = {
  fr: 'fr',
  es: 'es',
  de: 'de',
  it: 'it',
  us: 'us_en',
  gb: 'uk_en',
  ch: 'su_fr',
  tr: 'tu_en',
  ci: 'ci_fr',
  jp: 'jp_ja',
  ru: 'ru_ru',
  cn: 'ch_cn',
  kr: 'kr_kr',
  ae: 'ar_ar',
  sa: 'sa_ar',
}

const Geoip = () => {
  const geoipKey = process.env.NEXT_PUBLIC_GEOIP_API_KEY

  const locale = useCurrentLocale()
  const { addModal, removeModal } = useContext(ModalContext) as ModalContextType
  const checkFromCookie = getCookie('geoipCheck')

  const modalIsDisplayedRef = useRef(false)

  // Return user IP
  async function toJson(url: string) {
    const res = await fetch(url)
    return await res.json()
  }

  useEffect(() => {
    const fetchGeoipData = async function () {
      if (!checkFromCookie && geoipKey) {
        try {
          const data: geoipResult = await toJson(`https://api.ipdata.co/?api-key=${geoipKey}`)

          // if user IP is different from current locale
          const countryCode = data.country_code?.toLowerCase()
          const storeCode = storesByCountryCode[countryCode || '']

          if (data && storeCode && locale !== storeCode) {
            const uuid = crypto.randomUUID()

            const handleClose = () => {
              setCookie('geoipCheck', true)
              removeModal(uuid)
              document.body.classList.remove('no-overflow')
              modalIsDisplayedRef.current = false
            }

            if (!modalIsDisplayedRef.current) {
              modalIsDisplayedRef.current = true
              addModal({
                uuid,
                component: (
                  <ModalGeoip
                    uuid={uuid}
                    storeCode={storeCode}
                    countryName={data.country_name}
                    onClose={handleClose}
                  />
                ),
              })
            }
          }
        } catch (error) {
          console.error('Error fetching geoip data:', error)
        }
      }
    }

    fetchGeoipData()
  }, [checkFromCookie])

  return <></>
}

export default Geoip
