"use client"
import { useContext } from 'react'
import useProductStockStatusQuery from '../../../core/hook/useProductStockStatusQuery'
import { ConfigContext } from '../../context/ConfigContext'
import Icon from '../Icon/Icon'
import { Icons } from '../Icon/constants'

export default function PushSmallCartIcon(props: { sku?: string | null }) {
  const { isEcommerce } = useContext(ConfigContext)
  const query = useProductStockStatusQuery({ sku: props.sku, enabled: isEcommerce() })

  if (!isEcommerce() || query.isLoading || query.data?.stock_status !== 'IN_STOCK') {
    return null
  }

  return (
    <div className='selection-item-cart'>
      <Icon iconName={Icons.CART} />
    </div>
  )
}
