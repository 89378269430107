'use client'
import { HTMLAttributes, useEffect, useRef } from 'react'
import { useIntersectionObserver } from 'usehooks-ts'

// You can add more tags when needed
type AllowedTags = 'div' | 'h3' | 'h2' | 'h1'

// Update public/assets/styles/aos.scss to support more animation
type AosProps<HtmlTag extends AllowedTags = 'div'> = {
  htmlTag?: HtmlTag
  animation?: 'fade-up'
  delay?: 500
  duration?: 800
} & HTMLAttributes<JSX.IntrinsicElements[HtmlTag]>
export default function Aos<HtmlTag extends AllowedTags = 'div'>(props: AosProps<HtmlTag>) {
  const { 
    htmlTag: Tag = 'div', animation, delay, duration, 
      ...otherProps 
  } = props
  const { ref, isIntersecting } = useIntersectionObserver({
    freezeOnceVisible: true,
  })
  const rootRef = useRef<HTMLElement | null>(null)

  useEffect(() => {
    if (isIntersecting && rootRef.current) {
      const elem = rootRef.current
      elem.classList.add('aos-animate')
    }
  }, [isIntersecting])

  return (
    <Tag
      ref={(elem) => {
        ref(elem)
        rootRef.current = elem
      }}
      data-aos={animation || 'fade-up'}
      data-aos-duration={duration}
      data-aos-delay={delay}
      {...otherProps as any}
    />
  )
}
