"use client"
import { useContext, useState } from 'react'
import { useTranslation } from '../../context/ConfigContext'
import { AlertNotificationContext } from '../../context/AlertNotificationContext'
import { ModalContext, ModalContextType } from '../../context/ModalContext'
import dynamic from 'next/dynamic'
import { GtmProduct, gtmProductPush } from '../../utils/gtmHelpers'
import useAddProductToCart from '../../../core/hook/cartHooks/useAddProductToCart'
import { handleError } from '../../../core/hook/utils'
import clsx from 'clsx'
import Link from '../Link/Link'

const ModalCart = dynamic(() => import('../Modal/ModalCart'))

export default function PushSmallAddToCartBtn(props: {
  product: GtmProduct & { url_key: string },
  listId?: string
  listName?: string
  position?: number
  directAddToCart?: boolean
}) {
  const { product, listId, listName, position, directAddToCart } = props
  const [buttonIsLoading, setButtonIsLoading] = useState(false)
  const t = useTranslation()
  const { addNotification } = useContext(AlertNotificationContext)
  const { addModal, removeModal } = useContext(ModalContext) as ModalContextType
  const addProductToCart = useAddProductToCart()

  type CallbackAddProductParams =
    | {
        success: true
      }
    | {
        success: false
        error: string
      }

  const callbackAddProduct = (contextResponse: CallbackAddProductParams) => {
    setButtonIsLoading(false)
    if (!contextResponse.success) {
      addNotification(contextResponse.error)
      return
    }

    const uuid = crypto.randomUUID()
    addModal({
      uuid,
      component: <ModalCart uuid={uuid} onClose={() => removeModal(uuid)} />,
    })
  }

  const handleClickAddToCart = () => {
    if (product.item_id) {
      setButtonIsLoading(true)

      gtmProductPush({ event: 'add_to_cart', position, listId, listName, product })

      addProductToCart({ sku: product.item_id }, undefined, undefined, undefined)
        .then((res) => callbackAddProduct(res as CallbackAddProductParams))
        .catch((error) => {
          handleError(error, false)
        })
    }
  }

  const handleClick = () => {
    gtmProductPush({ event: 'select_item', position, listId, listName, product })
  }

  if (directAddToCart) {
    return (
      <button
        className={clsx('button black', { 'button--loading': buttonIsLoading })}
        onClick={handleClickAddToCart}>
        <span className='snake' />
        <span className='snake__overlay' />
        {t('Add to Cart')}
      </button>
    )
  }

  return (
    <Link href={product.url_key ?? ''} className='button black' onClick={handleClick}>
      {t('Add to Cart')}
    </Link>
  )
}
