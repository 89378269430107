'use client'

import { useEffect, useRef, useState, useContext } from 'react'
import Icon from '../Icon/Icon'
import { Icons } from '../Icon/constants'
import { ConfigContext } from '../../context/ConfigContext'

type GoToTopProps = {
  isAlone: boolean
  onLeft?: boolean
}

export const GoToTop = ({ isAlone, onLeft = false }: GoToTopProps) => {
  const ref = useRef<HTMLButtonElement>(null)

  const [visible, setVisible] = useState(false)

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop
    if (scrolled > 300) {
      setVisible(true)
    } else if (scrolled <= 300) {
      setVisible(false)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', toggleVisible)
  }, [])

  const handleReturnToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }
  const { isEcommerce } = useContext(ConfigContext)

  if (!isEcommerce()) {
    isAlone = true;
  }

  return (
    <button
      ref={ref}
      style={{ display: visible ? 'flex' : 'none' }}
      className={`to-top ${isAlone ? 'alone' : ''} ${onLeft ? 'onleft' : ''}`}
      aria-label='return to top'
      onClick={handleReturnToTop}>
      <Icon iconName={Icons.ARROW_TOP} width={24} height={24} />
    </button>
  )
}
