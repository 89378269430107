"use client"
import { useEffect } from 'react'
import { useIntersectionObserver } from 'usehooks-ts'
import { initSliderForPushSmall } from '../../utils/PushSmall.utils'
import { handleError } from '../../../core/hook/utils'

export default function PushSmallSwiper(props: {
  id?: string | number | null
  parentClassNameList?: string
  allowOnDesktop: boolean
  children: React.ReactNode
}) {
  const { id, parentClassNameList, allowOnDesktop } = props
  const { isIntersecting, ref } = useIntersectionObserver({
    threshold: 0,
    freezeOnceVisible: true,
  })
  const classNameSwiper = `push-small-swiper-${id}`
  const classNameSwiperPagination = `push-small-swiper-pagination-${id}`

  useEffect(() => {
    if (!isIntersecting) {
      return
    }
    try {
      const swiper = initSliderForPushSmall(
        classNameSwiper,
        classNameSwiperPagination,
        allowOnDesktop,
        parentClassNameList,
      )

      return () => {
        try {
          swiper?.destroy?.()
        } catch (error) {
          // TODO workaround
          handleError(error, false)
        }
      }
    } catch (error) {
      // TODO workaround
      handleError(error, false)
    }
  }, [
    parentClassNameList,
    classNameSwiper,
    classNameSwiperPagination,
    allowOnDesktop,
    isIntersecting,
  ])

  return (
    <>
      <div className={`swiper push-small-swiper ${classNameSwiper}`} ref={ref}>
        <div className='swiper-wrapper push-small-swiper-wrapper'>{props.children}</div>
      </div>
      <div className={`custom-slider-pagination ${classNameSwiperPagination}`} />
    </>
  )
}
