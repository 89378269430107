'use client'

import Swiper from 'swiper'
import { Pagination } from 'swiper/modules'

export const initSliderForPushSmall = (
  classNameSwiper: string,
  classNameSwiperPagination: string,
  allowSwiperOnDesktop: boolean,
  parentClassNameList?: string,
) => {
  let selectorClass = ''
  if (parentClassNameList) {
    selectorClass += `.${parentClassNameList} `
  }
  selectorClass += `.${classNameSwiper} `

  let selectorPaginationClass = ''
  if (parentClassNameList) {
    selectorPaginationClass += `.${parentClassNameList} `
  }
  selectorPaginationClass += `.${classNameSwiperPagination} `

  const swiper = new Swiper(selectorClass, {
    modules: [Pagination],
    slidesPerView: 1,
    loop: true,
    pagination: {
      clickable: false,
      el: selectorPaginationClass,
      bulletClass: 'custom-slider-pagination-bullet',
      bulletActiveClass: 'custom-slider-pagination-bullet-active',
    },
    containerModifierClass: 'push-small-swiper',
    breakpoints: {
      320: {
        threshold: 1,
        allowTouchMove: parentClassNameList !== 'list-selection',
      },
      960: {
        allowTouchMove: allowSwiperOnDesktop,
      },
    },
    lazyPreloadPrevNext: 1,
  })

  return swiper
}
