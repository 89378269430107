"use client"
import React, { useContext } from 'react'
import PushLink from './PushLink'
import PushSmallAddToCartBtn from './PushSmallAddToCartBtn'
import { ConfigContext, useTranslation } from '../../context/ConfigContext'
import useProductStockStatusQuery from '../../../core/hook/useProductStockStatusQuery'
import Loader from '../Loader/Loader'
import { GtmProduct } from '../../utils/gtmHelpers'

export default function PushSmallCta(props: {
  product: GtmProduct & { url_key: string }
  isSimpleProduct?: boolean
  listId?: string
  listName?: string
  position?: number
}) {
  const { isEcommerce } = useContext(ConfigContext)
  const { isSimpleProduct, listName, listId, position, product } = props
  const t = useTranslation()
  const query = useProductStockStatusQuery({ sku: product.item_id, enabled: isEcommerce() })
  const item = query.data
  const directAddToCart = isSimpleProduct && item?.stock_status === 'IN_STOCK'

  if (!isEcommerce() || item?.stock_status === 'OUT_OF_STOCK') {
    return (
      <PushLink
        className='button black'
        product={product}
        listId={listId}
        listName={listName}
        position={position}>
        {t('global-cta-discover')}
      </PushLink>
    )
  }

  if (query.isLoading) {
    return <Loader small />
  }

  return (
    <PushSmallAddToCartBtn
      product={product}
      directAddToCart={directAddToCart}
      listId={listId}
      listName={listName}
      position={position}
    />
  )
}
