'use client'
import { useContext } from 'react'
import useCurrentLocale from '../../../core/hook/useCurrentLocale'
import { getIsCoteIvoire } from '../../utils/locale.utils'
import { ConfigContext, useTranslation } from '../../context/ConfigContext'
import FormattedPrice from '../FormattedPrice/FormattedPrice'

export default function PushSmallPrice(props: {
  mariage?: number
  customPrice?: number
  priceRange: Product['price_range']
  typename?: string
}) {
  const { mariage, customPrice, priceRange, typename } = props
  const local = useCurrentLocale()
  const formatedPrice = !getIsCoteIvoire(local) ? (
    <FormattedPrice
      price={priceRange.minimum_price.regular_price.value}
      currency={priceRange.minimum_price.regular_price.currency}
    />
  ) : (
    ' '
  )
  const { storeConfig } = useContext(ConfigContext)
  const t = useTranslation()
  if (!storeConfig.display_prices) {
    return
  }
  return (
    <>
      {mariage === 1 ? (
        <>
          {/* Si Mariage & "display_custom_price" -> affichage du prix "à partir de" */}
          {customPrice === 1 ? (
            <p className='push-price'>
              <span className='extra'>{t('As low as', {}, true)}</span>
              {formatedPrice}
            </p>
          ) : (
            <p className='push-price'>
              {/* Si Mariage et "display_custom_price" === false -> affichaged'un libellé "prix sur demande" */}
              <span className='extra'>{t('Price on demand', {}, true)}</span>
            </p>
          )}
        </>
      ) : (
        <>
          {/* Si pas Mariage & "Bundle" -> affichage de "à partir de" + prix*/}
          {typename === 'BundleProduct' ? (
            <p className='push-price'>
              <span className='extra'>{t('As low as', {}, true)}</span>
              {formatedPrice}
            </p>
          ) : (
            <p className='push-price'>
              {/* Si pas Mariage & "Bundle" === false -> affiche du prix simplement*/}
              {formatedPrice}
            </p>
          )}
        </>
      )}
    </>
  )
}
