'use client'

import { GtmProduct, gtmProductPush } from "../../utils/gtmHelpers";
import Link from "../Link/Link";

export default function PushLink(props: {
  product: GtmProduct & { url_key: string },
  children: React.ReactNode,
  className?: string

  position?: number | string,
  listId?: string,
  listName?: string,
}) {
  const { product, position, listName, listId, children } = props;
  const handleClick = () => {
    gtmProductPush({ event: 'select_item', position, listId, listName, product })
  }
  return (
    <Link href={product.url_key ?? ''} className={props.className} onClick={handleClick}>
      {children}
    </Link>
  );
}
