import { FunctionComponent } from 'react'

import Icon from '../Icon/Icon'
import { Icons } from '../Icon/constants'

const Loading: FunctionComponent = () => (
  <div className='loading'>
    <Icon iconName={Icons.LOADING} />
  </div>
)

export default Loading
