'use client'
import { useQuery } from '@tanstack/react-query'
import { useTranslation, KeyTranslationOptions } from '../../context/ConfigContext'
import useCurrentLocale from '../../../core/hook/useCurrentLocale'

export function Message(props: {
  msgKey: string
  options?: KeyTranslationOptions
  allowTags?: boolean
}) {
  const t = useTranslation()
  const locale = useCurrentLocale()
  let allowTags = props.allowTags
  if (!allowTags) {
    allowTags = false
  }
  const maybeTranslated = t(props.msgKey, props.options || {}, allowTags, true)
  const msgQuery = useQuery({
    queryKey: ['translations', locale, props.msgKey],
    async queryFn() {
      const params = new URLSearchParams()
      params.set('locale', locale)
      params.set('keys', props.msgKey)
      const response = await fetch(`/api/translations?${params.toString()}`)
      const dict = (await response.json()) as Record<string, string>
      if (dict[props.msgKey]) {
        return dict[props.msgKey]
      }
      return null
    },
    enabled: maybeTranslated === null,
  })
  if (maybeTranslated) {
    return maybeTranslated
  }
  if (msgQuery.data) {
    return msgQuery.data
  }
  return props.msgKey
}
